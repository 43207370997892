<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1512 14.5163C11.13 14.703 11.1191 14.8929 11.1191 15.0853V22.0853H21.1191V19.0855L23.1191 17.055V21.0853L25.1191 23.0853V24.0853H7.11914V23.0853L9.11914 21.0853V15.0853C9.11914 11.7353 11.4791 8.93533 14.6191 8.25533V7.08533C14.6191 6.25533 15.2891 5.58533 16.1191 5.58533C16.9491 5.58533 17.6191 6.25533 17.6191 7.08533V8.25533C19.3507 8.63032 20.8451 9.65005 21.8373 11.0512L20.3945 12.4926C19.5172 11.0497 17.9301 10.0853 16.1191 10.0853C13.6065 10.0853 11.5247 11.9419 11.1719 14.3572L11.0806 14.4478L11.1512 14.5163ZM21.0497 14.2507C21.0827 14.4469 21.1043 14.647 21.1137 14.8502L22.845 13.1416C22.7946 12.9673 22.7377 12.7959 22.6744 12.6276L21.0497 14.2507ZM16.1191 27.0853C15.0191 27.0853 14.1291 26.1953 14.1291 25.0953H18.1091C18.1091 26.1953 17.2191 27.0853 16.1191 27.0853Z"
      fill="currentColor"
    />
    <path
      d="M18.1271 16.8864L14.9571 13.7164L13.5371 15.1264L18.1271 19.7164L27.1271 10.7164L25.7171 9.3064L18.1271 16.8864Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcBellCheck',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
